import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Orandi Harris | QA Engineer', // e.g: 'Name | Developer' 
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my portfolio!', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Greetings!',
  name: "I'm Orandi",
  subtitle: 'Tech Lover | Music Fan | Jamaican',
  cta: 'Click here to find out more!',
};

// ABOUT DATA
export const aboutData = {
  img: 'Orandi.png',
  paragraphOne: 'Hello! I am a QA Engineer based in Jamaica currently. I have worked on many different types of projects and web applications.',
  paragraphTwo: 'I am also a conference speaker who has presented at several international and local conferences. I am open to new learning opportunities.',
  paragraphThree: 'PS. Big music fan, football supporter and lover of all things in the tech space!',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'WebDriver.io Training',
    info: 'Simple repo to demonstrate how to use WebDriver.io for web automation 😎',
    info2: '',
    url: 'https://github.com/OrandiH/WebDriverIO-Training',
    repo: 'https://github.com/OrandiH/WebDriverIO-Training', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Percy & WebDriverIO',
    info: 'Sample repo to demonstrate how to use Percy.io and WebDriver.io 🔥',
    info2: '',
    url: 'https://github.com/OrandiH/Percy-WebDriverIO-Demo-App',
    repo: 'https://github.com/OrandiH/Percy-WebDriverIO-Demo-App', // if no repo, the button will not show up
  }
];

// CONTACT DATA
export const contactData = {
  cta: 'Wanna talk? Shoot me a email! 😁',
  btn: '',
  email: 'idnarosirrah@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/OH_TheDreamer',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/orandiharris/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/OrandiH',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
